import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  LAYOUT_TYPES,
  SKIN_LAYOUT_TYPES,
  LAYOUT_MODE_TYPES,
  LAYOUT_DIRECTION,
  LAYOUT_WIDTH_TYPES,
  LEFT_SIDEBAR_SIZE,
  NAVIGATION_TYPES,
  SIDEBAR_COLOR_TYPES,
  TOPBAR_COLOR_TYPES,
} from '../layout';

export interface LayoutModel {
  LAYOUT: string;
  LAYOUT_SKIN: string;
  LAYOUT_MODE: string;
  LAYOUT_DIRECTION: string;
  LAYOUT_WIDTH: string;
  SIDEBAR_SIZE: string;
  LAYOUT_NAVIGATION: string;
  SIDEBAR_COLOR: string;
  TOPBAR_COLOR: string;
}

export class ChangeLayout {
  static readonly type = '[LayoutState] SetLayout';
  constructor(public type: string) {}
}

export class ChangeSkin {
  static readonly type = '[LayoutState] SetSkin';
  constructor(public skin: string) {}
}

export class ChangeMode {
  static readonly type = '[LayoutState] SetMode';
  constructor(public mode: string) {}
}

export class ChangeDirection {
  static readonly type = '[LayoutState] SetDirection';
  constructor(public direction: string) {}
}

export class ChangeWidth {
  static readonly type = '[LayoutState] SetWidth';
  constructor(public width: string) {}
}

export class ChangeSideBarSize {
  static readonly type = '[LayoutState] SetSidebarSize';
  constructor(public size: string) {}
}

export class ChangeNavigation {
  static readonly type = '[LayoutState] SetNavigation';
  constructor(public navigation: string) {}
}

export class ChangeSidebarColor {
  static readonly type = '[LayoutState] SetSidebarColor';
  constructor(public sidebar_color: string) {}
}

export class ChangeTopbarColor {
  static readonly type = '[LayoutState] SetTopbarColor';
  constructor(public topbar_color: string) {}
}

@State<LayoutModel>({
  name: 'layout',
  defaults: {
    LAYOUT: LAYOUT_TYPES.VERTICAL,
    LAYOUT_SKIN: SKIN_LAYOUT_TYPES.DEFAULT,
    LAYOUT_MODE: LAYOUT_MODE_TYPES.LIGHTMODE,
    LAYOUT_DIRECTION: LAYOUT_DIRECTION.LTR,
    LAYOUT_WIDTH: LAYOUT_WIDTH_TYPES.FLUID,
    SIDEBAR_SIZE: LEFT_SIDEBAR_SIZE.DEFAULT,
    LAYOUT_NAVIGATION: NAVIGATION_TYPES.STICKY,
    SIDEBAR_COLOR: SIDEBAR_COLOR_TYPES.LIGHT,
    TOPBAR_COLOR: TOPBAR_COLOR_TYPES.LIGHT,
  },
})
@Injectable()
export class LayoutState {
  constructor() {}

  @Selector()
  static getLayout(state: LayoutModel) {
    return state;
  }

  @Selector()
  static getLayoutType(state: LayoutModel) {
    return state.LAYOUT;
  }

  @Selector()
  static getLayoutSkin(state: LayoutModel) {
    return state.LAYOUT_SKIN;
  }

  @Selector()
  static getLayoutmode(state: LayoutModel) {
    return state.LAYOUT_MODE;
  }

  @Selector()
  static getLayoutdirection(state: LayoutModel) {
    return state.LAYOUT_DIRECTION;
  }

  @Selector()
  static getSidebarsize(state: LayoutModel) {
    return state.SIDEBAR_SIZE;
  }

  @Selector()
  static getNavigation(state: LayoutModel) {
    return state.LAYOUT_NAVIGATION;
  }

  @Selector()
  static getSidebarcolor(state: LayoutModel) {
    return state.SIDEBAR_COLOR;
  }

  @Selector()
  static getTopbarcolor(state: LayoutModel) {
    return state.TOPBAR_COLOR;
  }

  @Selector()
  static getLayoutWidth(state: LayoutModel) {
    return state.LAYOUT_WIDTH;
  }

  @Action(ChangeLayout)
  changeLayout(states: StateContext<LayoutModel>, action: ChangeLayout) {
    states.patchState({
      LAYOUT: action.type,
    });
  }

  @Action(ChangeSkin)
  changeSkin(states: StateContext<LayoutModel>, action: ChangeSkin) {
    states.patchState({
      LAYOUT_SKIN: action.skin,
    });
  }

  @Action(ChangeMode)
  changeMode(states: StateContext<LayoutModel>, action: ChangeMode) {
    states.patchState({
      LAYOUT_MODE: action.mode,
    });
  }

  @Action(ChangeDirection)
  changeDirection(states: StateContext<LayoutModel>, action: ChangeDirection) {
    states.patchState({
      LAYOUT_DIRECTION: action.direction,
    });
  }

  @Action(ChangeWidth)
  changeWidth(states: StateContext<LayoutModel>, action: ChangeWidth) {
    states.patchState({
      LAYOUT_WIDTH: action.width,
    });
  }

  @Action(ChangeSideBarSize)
  changeSideBarSize(
    states: StateContext<LayoutModel>,
    action: ChangeSideBarSize
  ) {
    states.patchState({
      SIDEBAR_SIZE: action.size,
    });
  }

  @Action(ChangeNavigation)
  changeNavigation(
    states: StateContext<LayoutModel>,
    action: ChangeNavigation
  ) {
    states.patchState({
      LAYOUT_NAVIGATION: action.navigation,
    });
  }

  @Action(ChangeSidebarColor)
  changeSidebarColor(
    states: StateContext<LayoutModel>,
    action: ChangeSidebarColor
  ) {
    states.patchState({
      SIDEBAR_COLOR: action.sidebar_color,
    });
  }

  @Action(ChangeTopbarColor)
  changeTopbarColor(
    states: StateContext<LayoutModel>,
    action: ChangeTopbarColor
  ) {
    states.patchState({
      TOPBAR_COLOR: action.topbar_color,
    });
  }
}
